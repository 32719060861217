import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from "react-router-dom";
import history from "./history";
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-MH7BS5B'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<Router history={history}>
		<Route component={App} />
	</Router>,
	document.getElementById("root")
);

serviceWorker.register();
